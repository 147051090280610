const generateUID = () => {
	const chars = "1234567890qwertyuiopasdfghjklzxcvbnmQWERTIOPASDFGHKLZXCVBNM";
	return (new Array(12))
		.fill()
		.reduce((acc /* val */) => `${acc}${chars.charAt(Math.floor(Math.random() * chars.length))}`, "");
};

function isGetParameterInRestrictedScope(envName) {
	const currentLocation = window.location.host || "";

	if (currentLocation === "plugins.chamaileon.io") {
		return true;
	}

	if (currentLocation.includes("localhost") && envName.includes("dev")) {
		return true;
	}

	return false;
}

export function setAndGetUID(envName) {
	try {
		let uidValue;

		// do not break the full function if the localStorage is not accessible
		try {
			uidValue = localStorage.getItem("uid");
		} catch (e) {
			console.warn("localStorage is not accessible");
		}

		if (!uidValue && isGetParameterInRestrictedScope(envName)) {
			const urlParams = new URLSearchParams(window.location.search);
			uidValue = urlParams.get("chamUid");
		}

		if (!uidValue) {
			const newUid = generateUID();
			localStorage.setItem("uid", newUid);
			uidValue = newUid;
		}

		return uidValue;
	} catch (error) {
		console.error(error);
	}
}

export function getDomain(envName) {
	try {
		let returnDomain = "";

		const urlParams = new URLSearchParams(window.location.search);
		const domain = urlParams.get("chamDomain");

		if (domain && isGetParameterInRestrictedScope(envName)) {
			returnDomain = decodeURIComponent(domain);
		} else if (location.ancestorOrigins && location.ancestorOrigins.length) {
			returnDomain = location.ancestorOrigins[location.ancestorOrigins.length - 1];
		} else {
			returnDomain = (window.location !== window.parent.location)
				? document.referrer
				: document.location.host;
		}

		// remove http and https from the url
		returnDomain = (returnDomain || "").replace(/^https?:\/\//, "");

		if (returnDomain.includes("/")) {
			// if the URL has more information then split it and only keep the hostname part
			// This comes from the document.referrer prop
			returnDomain = returnDomain.split("/")[0];
		}

		return returnDomain;
	} catch (error) {
		console.error(error);
	}
}
